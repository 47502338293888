import React from 'react';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Layout from '../components/Layout';
import Bio from '../components/Bio';
import SharingButtons from '../components/SharingButton';
import ProgressScroll from '../components/ProgressScroll';
import {
  SiteSiteMetadata,
  MarkdownRemark,
  File,
} from '../../types/graphql-types';
import PC_BEAKPOINT from '../constants';

const H1 = styled.h1`
  line-height: 1.2;
  margin: 0 0 0.4rem;
`;

const DateWrapper = styled.p`
  margin-bottom: 0.4rem;
  padding: 0 0.8rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }
`;

const TagLists = styled.ul`
  list-style: none;
  margin-left: 0;
  padding: 0 0.8rem;
  margin: 0 0 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  li {
    margin-right: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }
`;

const ShareButtonWrapper = styled.div`
  padding: 0 0.8rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }
`;

const PoplarListDescription = styled.div`
  padding: 0 0.8rem 1.6rem;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 1.6rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0 0 1.6rem;
  }
`;

const ThumbnailWrapper = styled.div`
  margin-bottom: 0.8rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }
`;

const ContentBody = styled.section`
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 3.2rem;
  margin-bottom: 1.6rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul {
    padding: 0 0.8rem;

    @media (min-width: ${PC_BEAKPOINT}) {
      padding: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 2.2rem;
    font-weight: 600;
    margin: 2.4rem 0 1.6rem;
  }

  p,
  ul {
    margin-bottom: 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: 2rem;
  }
`;

const PostHeader = styled.div`
  margin-bottom: 2.4rem;
`;

const PostTitle = styled.div`
  font-size: 2.1rem;
  line-height: 1.3;
  margin-bottom: 0.8rem;
  margin-top: 1.4rem;
  padding: 0 0.8rem;

  @media (min-width: ${PC_BEAKPOINT}) {
    padding: 0;
  }
`;

interface BlogPostProps {
  data: {
    pageViews?: {
      totalCount: number;
    };
    site: {
      siteMetadata: SiteSiteMetadata;
    };
    markdownRemark: MarkdownRemark;
    file: File;
  };
  location: Location;
}

const BlogPost: React.FC<BlogPostProps> = ({
  data,
  location,
}: BlogPostProps) => {
  const { markdownRemark: post, file, pageViews } = data;
  const { title, siteUrl, author, description } = data.site.siteMetadata;

  const { featuredImage } = post.frontmatter;
  const imagePath =
    featuredImage && featuredImage.childImageSharp
      ? `${siteUrl}${featuredImage.childImageSharp.resize.src}`
      : file.childImageSharp.fluid.src;

  const metaDescription: string = post.frontmatter.description || description;

  return (
    <Layout location={location} title={title}>
      <ProgressScroll height="6" duration="0.1" />
      <Helmet
        title={`${post.frontmatter.title} | Blog`}
        meta={[
          { name: 'description', content: metaDescription },
          {
            property: 'og:title',
            content: `${post.frontmatter.title}`,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: 'og:image',
            content: imagePath,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: author,
          },
          {
            name: `twitter:title`,
            content: `${post.frontmatter.title}`,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]}
      />
      <article>
        <PostHeader>
          <H1>
            <ThumbnailWrapper>
              <Image
                fluid={
                  featuredImage && featuredImage.childImageSharp
                    ? featuredImage.childImageSharp.fluid
                    : file.childImageSharp.fluid
                }
                alt={title}
              />
            </ThumbnailWrapper>

            <PostTitle>{post.frontmatter.title}</PostTitle>
          </H1>
          <DateWrapper>{post.frontmatter.date}</DateWrapper>
          {post.frontmatter.tags.length > 0 && (
            <TagLists>
              {post.frontmatter.tags.map(tag => (
                <li key={tag}>
                  <Link to={`/tags/${tag}/`}>#{tag}</Link>
                </li>
              ))}
            </TagLists>
          )}
          <ShareButtonWrapper>
            <SharingButtons
              title={post.frontmatter.title}
              url={`${siteUrl}${location.pathname}`}
            />
          </ShareButtonWrapper>
        </PostHeader>
        {/* eslint-disable-next-line react/no-danger */}
        <ContentBody dangerouslySetInnerHTML={{ __html: post.html }} />

        <PoplarListDescription>
          Weekly number of page views since last build：{' '}
          {[...Array(pageViews?.totalCount)].map((_, index) => (
            <span key={index}>🥰</span>
          )) || <span>🥺</span>}{' '}
        </PoplarListDescription>
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query PostBigQurey($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        featuredImage {
          publicURL
          extension
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1500, quality: 100) {
              src
            }
          }
        }
      }
    }
    pageViews(id: { eq: $slug }) {
      totalCount
    }
    site {
      siteMetadata {
        title
        siteUrl
        author
        description
      }
    }
    file(relativePath: { eq: "dummy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
