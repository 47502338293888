import React, { useState, useEffect } from 'react';

interface Props {
  duration?: string;
  height?: string;
}

export const ProgressScroll: React.FC<Props> = ({
  duration,
  height,
}: Props) => {
  const [width, setWidth] = useState<string>(null);

  const onScroll = (): void => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const heightDiff =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolledParameter = (windowScroll / heightDiff) * 100;
    setWidth(heightDiff > 0 ? `${scrolledParameter}%` : null);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return (): void => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [width]);

  return (
    <div
      style={{
        backgroundColor: '#fff000',
        height: `${height || '3'}px`,
        position: 'fixed',
        top: 0,
        left: 0,
        transitionProperty: 'width',
        transitionDuration: `${duration || '0.5'}s`,
        transitionTimingFunction: `ease-out`,
        width,
        zIndex: 100,
      }}
    />
  );
};

export default ProgressScroll;
